const _cloudfrontHost = "https://d3gt86tqygu4yt.cloudfront.net/";
// const _awsS3HostOne = "https://windmill-images.s3.ap-south-1.amazonaws.com/";
// const _awsS3HostTwo = "https://windmill-assets.s3.ap-south-1.amazonaws.com/";
// const _imageKitHost = "https://d3eavn3p0apacm.cloudfront.net/";

export function getImageUrl(url: any) {
  if (!url || url === "") {
    return "No Image Key";
  } else if (url[0] === "/") {
    return _cloudfrontHost + url.slice(1, url.length);
  } else {
    return _cloudfrontHost + url;
  }
}
