import React from "react";
import moment from "moment/moment";

const Experience = ({ resumeDetail }) => {
  return (
      <>
          {resumeDetail?.experience_segments?.map((item, index) => (
              <div>
                  {item.original_input.map((data, dataIndex) => (
                      <div key={data._id} className="border card border-custom-200 dark:border-custom-800">
                          <div className="card-body">
                              <p>
                                  <span className="mr-1 font-semibold">
                          Company Name:
                        </span>{" "}
                                  {data.company_name}
                              </p>
                              <p>
                                  <span className="mr-1 font-semibold">Location:</span>{" "}
                                  {data.location}
                              </p>
                              <p>
                                  <span className="mr-1 font-semibold">Position:</span>{" "}
                                  {data.position}
                              </p>
                              <p className="text-gray-700 dark:text-gray-300">
                                  <span className="mr-1">Start Date:</span>
                                  {moment(new Date(data.start_date)).format("DD MMM YYYY")}
                                  <span className="mr-1" style={{marginLeft: "20px"}}>End Date:</span>{"        "}
                                  {moment(new Date(data.end_date)).format("DD MMM YYYY")}
                              </p>
                              <p>
                                  <span className="mr-1 font-semibold">Position:</span>{" "}
                                  {data.position}
                              </p>
                              <p>
                                  <span className="mr-1 font-semibold">Description:</span>{" "}
                                  {data.description}
                              </p>
                          </div>
                      </div>
                  ))}
              </div>
          ))}
      </>
  );
};

export default Experience;
