// dashboard
import Home from "pages/Home";

import UserProfile from "pages/Authentication/UserProfile";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import UserList from "pages/Customer/UserList";
import Admins from "pages/Admin";
import MediaList from "pages/MediaList";
import UserPost from "pages/UserPost";
import HostedEvent from "pages/HostedEvent";
import NewsList from "pages/NewsList";
import ResetPassword from "pages/Authentication/ResetPassword";
import ForgetPassword from "pages/Authentication/forgetPassword";
interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  // { path: "/", component: Home },

  // profile
  { path: "/user-profile", component: UserProfile },
  //userlist
  { path: "/admins", component: Admins },
  { path: "/", component: UserList },

  { path: "/medialist", component: MediaList },
  { path: "/userpost", component: UserPost },
  { path: "/hostedevent", component: HostedEvent },
  { path: "/newslist", component: NewsList },
];

const publicRoutes = [
  // authentication
  { path: "/forget-password", component: ForgetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
