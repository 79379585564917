import React from "react";

const BasicInformation = ({ resumeDetail }) => {
  return (
    <div>
      <div className="mt-4">
        {resumeDetail?.custom_segments?.map((item) => {
          return (
            <>
              {/*<div className="max-w-md mx-auto overflow-hidden bg-gray-100 shadow-lg dark:bg-gray-800 rounded-xl">*/}
              {/*  <div className="p-6">*/}
              {/*    <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-900">*/}
              {/*      <h2 className="mb-2 text-2xl font-bold text-gray-800 dark:text-white">*/}
              {/*        {item.title}*/}
              {/*      </h2>*/}

              {/*      <p className="mb-2 text-lg text-gray-700 dark:text-gray-300">*/}
              {/*        <span className="mr-1 font-semibold">description:</span>{" "}*/}
              {/*        {item.original_input.map((e) => e.description).join(",")}*/}
              {/*        /!* {item.original_input.map((data) => {*/}
              {/*          return (*/}
              {/*            <>*/}
              {/*        <span>{data.description}</span>;*/}
              {/*            </>*/}
              {/*          );*/}
              {/*        })} *!/*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default BasicInformation;
