import React, { useEffect } from "react";
import "./assets/scss/themes.scss";
import RouteIndex from "Routes/Index";
import { loginSuccess, headingData } from "slices/auth/login/reducer";
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useDispatch } from "react-redux";

// Activating fake backend
fakeBackend();

function App() {
  let dispatch = useDispatch();
  const userDetail = JSON.parse(window.localStorage.getItem(`authUser`));
  console.log(userDetail);
  useEffect(() => {
    if (userDetail) {
      dispatch(loginSuccess(userDetail));
      dispatch(headingData(userDetail));
    }
  }, [userDetail]);

  return <RouteIndex />;
}

export default App;
