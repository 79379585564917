import React, { useState } from "react";

const ReadMore = ({ text, length = 30 }: { text: string; length?: number }) => {
  const [open, setOpen] = useState(false);

  if (open)
    return (
      <div>
        {text}{" "}
        <span
          className="text-orange-500 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          less
        </span>
      </div>
    );

  if (text?.length < length) return <div>{text}</div>;

  return (
    <div>
      {text?.substring(0, length)}...{" "}
      <span
        className="text-orange-500 cursor-pointer"
        onClick={() => setOpen(true)}
      >
        more
      </span>
    </div>
  );
};

export default ReadMore;
