import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  Handshake,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Building,
  Trophy,
  UserCog,
  UserCog2,
  UserRound,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "adminlist",
    label: "Admins",
    link: "/admins",
    icon: <LocateFixed />,
  },
  {
    id: "userlist",
    label: "Customers",
    link: "/",
    icon: <UserRound />,
  },
  // {
  //   id: "medialist",
  //   label: "Media List",
  //   link: "/medialist",
  //   icon: <MonitorDot />,
  // },

  // {
  //   id: "userpost",
  //   label: "Customer Posts",
  //   link: "/userpost",
  //   icon: <MessageSquare />,
  // },
  // {
  //   id: "hostedevent",
  //   label: "Hosted Events",
  //   link: "/hostedevent",
  //   icon: <Building />,
  // },
  // {
  //   id: "newslist",
  //   label: "News List",
  //   link: "/newslist",
  //   icon: <Mail />,
  // },
];

export { menuData };
