import BreadCrumb from "Common/BreadCrumb";
import { PaginationState } from "@tanstack/react-table";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import { MoreHorizontal, Eye, FileEdit, Trash2 } from "lucide-react";
import { Dropdown } from "Common/Components/Dropdown";
import {
  Download,
  Plus,
  Search,
  SlidersHorizontal,
  X,
  UploadCloud,
} from "lucide-react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import Drawer from "Common/Components/Drawer";
import Select from "react-select";
import callApi from "Common/utils/apiCaller";
import { Pencil } from "lucide-react";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import _ from "lodash";
import { getImageUrl } from "Common/utils/ImageDomain";
import { title } from "process";
import ReadMore from "Common/Readmore";
import axios from "axios";
const url = `https://api.athhub.com`;

const NewsList = () => {
  const [adminData, setAdminData] = useState([]);
  const [read, setRead] = useState<any>({ img_read: false });
  const [readText, setReadText] = useState<any>({ img_read: false });
  const [isAdminBlocked, setIsAdminBlocked] = useState<any>();
  const [mediaType, setMediaType] = useState<any>();
  const [showImage, setShowImage] = useState<any>(true);
  const [pageCount, setPageCount] = useState(0);
  const [imageLink, setImageLink] = useState<any>();
  const [drawerEnd, setDrawerEnd] = useState(false);
  const [actionData, setActionData] = useState<any>();
  const [action, setAction] = useState<any>();
  const [isReported, setIsReported] = useState<any>();
  const [search, setSearch] = useState<any>();
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState(0);
  const [render, setRender] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);
  const drawerEndToggle = (data: any) => {
    setDrawerEnd(!drawerEnd);
    setActionData(data);
  };
  const debouncedHandler = useCallback(
    _.debounce(
      (
        user: any,
        isReported: any,
        pagination: any,
        render: any,
        isAdminBlocked: any
      ) => {
        return fetchDateWithFilters(
          user,
          isReported,
          pagination,
          render,
          isAdminBlocked
        );
      },
      700
    ),
    []
  );
  async function fetchDateWithFilters(
    searchByName: any,
    isReported: any,
    pagination: any,
    render: any,
    isAdminBlocked: any
  ) {
    let filters: { [index: string]: any } = {};
    if (searchByName) {
      filters.qtext = searchByName;
    }
    if (isReported === true) {
      filters.is_reported_by_user = isReported;
    } else if (isReported === false) {
      filters.is_reported_by_user = isReported;
    }
    if (isAdminBlocked === true) {
      filters.is_blocked_by_admin = isAdminBlocked;
    } else if (isAdminBlocked === false) {
      filters.is_blocked_by_admin = isAdminBlocked;
    }

    const localData = await callApi("v1/news/list", "post", {
      pageNum: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      filters,
    });

    if (localData.status === "Success") {
      setAdminData(localData.data.newsList);
      setPageCount(Math.ceil(localData.data.newsCount / pagination.pageSize));
      setTotalItems(localData.data.newsCount);
    }
  }

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["name", "designation", "location", "email", "status"];
    setSearch(search);
  };
  const options = [
    { value: "Select...", label: "Select Reported Status" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const adminOptions = [
    { value: "Select...", label: "Select Admin Status" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const optionsAction = [
    { value: "Select...", label: "Select Action " },
    { value: "block", label: "Block" },
    { value: "unblock", label: "Unblock" },
    { value: "ignore", label: "Ignore" },
  ];
  const handleAdminChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setIsAdminBlocked("");
    } else {
      if (selectedOption.value === "Yes") {
        setIsAdminBlocked(true);
      } else {
        setIsAdminBlocked(false);
      }
    }
  };
  const handleActionChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setAction("");
    } else {
      setAction(selectedOption.value);
    }
  };
  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setIsReported("");
    } else {
      if (selectedOption.value === "Yes") {
        setIsReported(true);
      } else {
        setIsReported(false);
      }
    }
  };
  useEffect(() => {
    debouncedHandler(search, isReported, pagination, render, isAdminBlocked);
  }, [search, isReported, pagination, render, isAdminBlocked]);
  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };
  console.log(eventData);

  const handleDelete = async () => {
    if (eventData) {
      //dispatch(onDeleteEmployee(eventData.id));

      const res = await callApi(
        `v1/news/${eventData?._id}/update-new`,
        "post",
        {
          newData: { is_deleted: true },
        }
      );
      if (res && res?.status === "Success") {
        setDeleteModal(false);
        setRender(!render);

        toast("Deleted successfully");
      }
    }
  };
  //
  useEffect(() => {
    if (eventData?.image_url) {
      setImageLink(eventData?.image_url);
      setShowImage(false);
    }
  }, [eventData]);
  const uploadPhoto = async (file: any) => {
    setMediaType(file?.type);
    let type1 = "";
    if (file?.type?.[0] === "v") {
      type1 = "video";
    } else {
      type1 = "image";
    }
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const ress = await axios({
      method: "post",
      url: `${url}/api/v1/media/upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (ress) {
      setShowImage(false);
      setImageLink(ress?.data?.link);
      // setImages();
    }
  };
  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  const handleActionOnReport = async (data: any, action: any) => {
    const res = await callApi(
      `v1/news/admin/news/${data?._id}/action`,
      "post",
      {
        action: action,
      }
    );

    if (res && res.status === "Success") {
      drawerEndToggle(data);
      setRender(!render);
      toast("updated successfully");
    }
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // employeeId: (eventData && eventData.employeeId) || "",
      title: (eventData && eventData.title) || "",
      text: (eventData && eventData.text) || "",

      // : (eventData && eventData.location_link) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Post Type"),
      text: Yup.string().required("Please Enter Location Name"),

      // location_link: Yup.string().required("Please Enter Location Link"),
    }),

    onSubmit: async (values) => {
      if (isEdit) {
        const updateData = {
          _id: eventData ? eventData._id : 0,
          ...values,
        };
        // update user

        const res = await callApi(
          `v1/news/${eventData?._id}/update-new`,
          "post",
          {
            newData: { ...values, image_url: imageLink },
          }
        );
        if (res && res?.status === "Success") {
          setRender(!render);
          toast("Updated successfully");
        }
        // dispatch(onUpdateEmployee(updateData));
      } else {
        // const res = await callApi(`v1/news/${customer?._id}/create-new`, "post", {
        //   userpost: {
        //     post_type: values?.post_type,
        //     posted_by: "UserID",
        //     location_name: values?.location_name,
        //     location_link: values?.location_link,
        //     reporting_by: "",
        //     reporting_reason: "",
        //   },
        // });
        // if (res && res?.status === "Success") {
        //   setRender(!render);
        //   toast("User created successfully");
        // }
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setSelectedImage("");
    } else {
      setShow(true);
      setEventData("");
      setSelectedImage("");
      validation.resetForm();
    }
  }, [show, validation]);
  useEffect(() => {
    if (imageLink === "") {
      setShowImage(true);
    }
  }, [imageLink]);
  const handleRemoveImage = (id: any) => {
    let arr = [];
    for (let item of imageLink) {
      if (id !== item?._id) {
        arr.push(item);
      }
    }
    setImageLink(arr);
  };

  const adminListColumns = useMemo(
    () => [
      {
        header: "Title",
        // accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Link to="#!" className="flex items-center gap-3">
            <ReadMore text={cell.row.original.title} length={30} />
          </Link>
        ),
      },
      {
        header: "Image",
        // accessorKey: "image_url",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Link
            to={getImageUrl(cell.row.original.image_url)}
            className="flex items-center gap-3"
            target="_blank"
          >
            <div className="rounded-full size-6 shrink-0 bg-slate-100">
              <img
                src={getImageUrl(cell.row.original.image_url)}
                alt=""
                className="h-6 rounded-full"
              />
            </div>
          </Link>
        ),
      },
      {
        header: "Posted By",
        // accessorKey: "posted_by",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Link to="#!" className="flex items-center gap-3">
            {/* <div className="rounded-full size-6 shrink-0 bg-slate-100">
              <img
                src={cell.row.original.profession_profile_id?.profile_type}
                alt=""
                className="h-6 rounded-full"
              />
            </div> */}
            <h6 className="grow">
              {cell.row.original.posted_by?.first_name}{" "}
              {cell.row.original.posted_by?.last_name}
            </h6>
          </Link>
        ),
      },
      // {
      //   header: "Reported By",
      //   accessorKey: "reporting_by",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        header: "Text",
        accessorKey: "text",
        enableColumnFilter: false,
        enableSorting: false,

        cell: (cell: any) => (
          <Link to="#!" className="flex items-center gap-3">
            <ReadMore text={cell.row.original.text} length={30} />
          </Link>
        ),
      },
      {
        header: "Reported Reason",
        accessorKey: "reporting_reason",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Blocked By Admin",
        accessorKey: "is_blocked_by_admin",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) => <>{cell.getValue() ? "YES" : "NO"}</>,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction5"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3"></MoreHorizontal>
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction5"
            >
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;

                    drawerEndToggle(data);
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Action by Admin </span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    onClickDelete(data);
                  }}
                >
                  <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Delete</span>
                </Link>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    [read, readText]
  );

  return (
    <React.Fragment>
      <BreadCrumb title="News List" pageTitle="News List" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-12 gap-x-5">
        <div className="col-span-12 card 2xl:col-span-12">
          <div className="card-body">
            <div className="flex items-center">
              <h6 className="text-15 grow">News List</h6>
              <div className="shrink-0">
                {/* <button
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  onClick={toggle}
                >
                  <Plus className="inline-block size-4" />{" "}
                  <span className="align-middle">Add Customer Posts</span>
                </button> */}
              </div>
            </div>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for title"
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
                <div className="xl:col-span-2">
                  <Select
                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    options={options}
                    isSearchable={false}
                    defaultValue={options[0]}
                    onChange={(event: any) => handleChange(event)}
                    id="choices-single-default"
                  />
                </div>
                <div className="xl:col-span-2">
                  <Select
                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    options={adminOptions}
                    isSearchable={false}
                    defaultValue={adminOptions[0]}
                    onChange={(event: any) => handleAdminChange(event)}
                    id="choices-single-default"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="card-body">
            <TableContainer
              isPagination={true}
              columns={adminListColumns || []}
              data={adminData || []}
              pageCount={pageCount}
              pagination={pagination}
              totalItems={totalItems}
              onPaginationChange={(updater: any) => {
                const nextState = updater(pagination);
                if (nextState.pageIndex !== pagination.pageIndex)
                  setPagination(nextState);
              }}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      </div>
      {/* Employee Modal */}
      <Drawer
        show={show}
        onHide={toggle}
        id="drawerEnd"
        drawer-end="true"
        className="fixed inset-y-0 flex flex-col w-1/2 transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 z-drawer dark:bg-zink-600"
      >
        <Drawer.Header
          className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
          closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
        >
          <Drawer.Title className="text-15">
            {!!isEdit ? "Edit News Post" : "Add News Post"}
          </Drawer.Title>
          <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
        </Drawer.Header>
        <Drawer.Body className="h-full p-4 overflow-y-auto">
          <form
            className="create-form"
            id="create-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" value="" name="id" id="id" />
            <input type="hidden" value="add" name="action" id="action" />
            <input type="hidden" id="id-field" />
            <div
              id="alert-error-msg"
              className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20"
            ></div>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="employeeId"
                  className="inline-block mb-2 text-base font-medium"
                >
                  User ID
                </label>
                <input
                  type="text"
                  id="employeeId"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.employeeId || "#TWE1001557"}
                  disabled
                />
              </div> */}
              <div className="xl:col-span-12">
                <label
                  htmlFor="posted_by"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="posted_by"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter Title"
                  name="title"
                  onChange={validation.handleChange}
                  value={validation.values.title || ""}
                />
                {validation.touched.title && validation.errors.title ? (
                  <p className="text-red-400">{validation.errors.title}</p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="locationInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Text
                </label>
                <textarea
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter Text"
                  disabled
                  rows={5}
                  name="text"
                  onChange={validation.handleChange}
                  value={validation.values.text || ""}
                />
                {validation.touched.text && validation.errors.text ? (
                  <p className="text-red-400">{validation.errors.text}</p>
                ) : null}
              </div>
              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="employeeInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Location Latitude
                </label>
                <input
                  type="text"
                  id="employeeInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter Location Latitude"
                  name="location_latitude"
                  onChange={validation.handleChange}
                  value={validation.values.location_latitude || ""}
                />
                {validation.touched.location_latitude &&
                validation.errors.location_latitude ? (
                  <p className="text-red-400">
                    {validation.errors.location_latitude}
                  </p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="location_longitude"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Location Longitude
                </label>
                <input
                  type="text"
                  id="location_longitude"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter location longitude "
                  name="location_longitude"
                  onChange={validation.handleChange}
                  value={validation.values.location_longitude || ""}
                />
                {validation.touched.location_longitude &&
                validation.errors.location_longitude ? (
                  <p className="text-red-400">
                    {validation.errors.location_longitude}
                  </p>
                ) : null}
              </div> */}
              <div className="xl:col-span-12">
                {/* <label
                  htmlFor="location_link"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Location Link
                </label>
                <input
                  type="text"
                  id="location_link"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter location link "
                  name="location_link"
                  onChange={validation.handleChange}
                  value={validation.values.location_link || ""}
                />
                {validation.touched.location_link &&
                validation.errors.location_link ? (
                  <p className="text-red-400">
                    {validation.errors.location_link}
                  </p>
                ) : null} */}
                {showImage ? (
                  <div
                    style={{ border: "1px solid #e2e8f0", padding: "10px" }}
                    className="flex items-center justify-center gap-2 mt-4 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 "
                  >
                    <input
                      type="file"
                      hidden
                      id="uploadimage49"
                      accept="image/*"
                      className="hidden profile-img-file-input"
                      onChange={(e: any) => uploadPhoto(e.target.files[0])}
                    />
                    <label
                      htmlFor="uploadimage49"
                      className="flex flex-col items-center justify-center cursor-pointer "
                    >
                      <div className="mb-3">
                        <UploadCloud className="block mx-auto size-12 text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                      </div>

                      <h5 className="mb-0 font-normal text-slate-500 text-15">
                        Drag and drop your files or <a href="#!">browse</a> your
                        files
                      </h5>
                    </label>
                  </div>
                ) : (
                  <div>
                    <div
                      className="flex flex-wrap justify-center gap-2 mt-4"
                      style={{
                        border: "1px solid #e2e8f0",
                        position: "relative",
                      }}
                    >
                      {imageLink ? (
                        <>
                          {imageLink.slice(
                            imageLink?.length - 3,
                            imageLink?.length
                          ) === "mp4" ? (
                            <iframe src={getImageUrl(imageLink)} />
                          ) : (
                            <img
                              src={getImageUrl(imageLink)}
                              width={70}
                              height={70}
                              alt={"img"}
                              style={{ margin: "10px", borderRadius: "10px" }}
                            />
                          )}
                          <div
                            style={
                              {
                                // position: "absolute",
                              }
                            }
                            onClick={() => {
                              setImageLink("");
                            }}
                          >
                            <X></X>
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="flex justify-center gap-2 mt-4">
                      <input
                        type="file"
                        hidden
                        id="uploadimage79"
                        onChange={(e: any) => uploadPhoto(e.target.files[0])}
                      />

                      <label
                        htmlFor="uploadimage79"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                      >
                        Upload Media
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="xl:col-span-6">
                <label
                  htmlFor="experienceInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Experience
                </label>
                <input
                  type="number"
                  id="experienceInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="0.0"
                  name="experience"
                  onChange={validation.handleChange}
                  value={validation.values.experience || ""}
                />
                {validation.touched.experience &&
                validation.errors.experience ? (
                  <p className="text-red-400">{validation.errors.experience}</p>
                ) : null}
              </div> */}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                id="close-modal"
                data-modal-close="addEmployeeModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                id="addNew"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Add News Post"}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>
      <Drawer
        show={drawerEnd}
        onHide={drawerEndToggle}
        id="drawerEnd"
        drawer-end="true"
        className="fixed inset-y-0 flex flex-col w-1/2 transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 z-drawer dark:bg-zink-600"
      >
        <Drawer.Header
          className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
          closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
        >
          <Drawer.Title className="text-15">
            Action on reported hosted event
          </Drawer.Title>
          <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
        </Drawer.Header>
        <Drawer.Body className="h-full p-4 overflow-y-auto">
          <div className="xl:col-span-2 min-h-[180px]">
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={optionsAction}
              isSearchable={false}
              defaultValue={optionsAction[0]}
              onChange={(event: any) => handleActionChange(event)}
              id="choices-single-default"
            />
          </div>
          <div className="flex justify-end gap-2 p-3 mt-4">
            <button
              type="button"
              id="close-modal9"
              data-modal-close="addEmployeeModal"
              className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={() => {
                handleActionOnReport(actionData, action);
              }}
            >
              submit
            </button>
          </div>
        </Drawer.Body>
      </Drawer>
    </React.Fragment>
  );
};

export default NewsList;
